.container {
  background-image: url("../../imgs/pata3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  overflow: hidden;
  box-sizing: border-box;
}

.formContainer {
  width: 100%;
  max-width: 600px;
  background: rgba(255, 255, 255, 0.95);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  box-sizing: border-box;
}

.headerTitle {
  font-family: "Montserrat", sans-serif;
  color: #266364;
  text-align: center;
  margin-bottom: 5px;
}

.subHeaderTitle {
  font-family: "Montserrat", sans-serif;
  color: #266364;
  text-align: center;
  margin-bottom: 15px;
}

.form {
  display: flex;
  flex-direction: column;
}

button {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  background-color: #2196f3;
}

button:hover {
  background-color: #1976d2;
}

@media (max-width: 600px) {
  .headerTitle {
    font-size: 1.5em;
  }
  .subHeaderTitle {
    font-size: 1em;
  }
  .formContainer {
    padding: 15px;
    width: 100%;
    margin: 0 auto;
  }
  .container {
    padding: 10px;
    background-size: cover;
    justify-content: flex-start;
  }
}
