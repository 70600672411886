.container {
  width: 100%;
  margin-top: 0;
  background-color: #e2f97d; /*#5fcd*/
  display: flex;
  height: 7.5%;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
}
.logo {
  height: 100%;
}

.links {
  align-items: center;
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.links a,
.links div {
  height: 100%;
  text-align: center;
  text-decoration: none;
  border-right: 0.2px solid rgba(212, 166, 126, 0.055);
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: white;
  font-size: 1em;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #266364;
  transition: background-color 0.3s ease; /* Transição de 0.3s no background-color */
}
.links div a {
  border-radius: 10px;
  color: #266364;
}
.links a:hover {
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: #f1ffb1;
}
.links .about {
  border: none;
}

@media (max-width: 1200px) {
  .links .about {
    display: none;
  }
  .links .appointments {
    border: none;
  }
}

@media (max-width: 800px) {
  .links .appointments {
    display: none;
  }
  .links .products {
    border: none;
  }
}
.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
}

.submenu a {
  padding: 10px;
  text-decoration: none;
  color: #333;
  border-bottom: 1px solid #ddd;
}

.submenu a:last-child {
  border-bottom: none;
}

.links .appointments:hover {
  height: 400px;
  background-color: #333;
}
@media (max-width: 900px) {
  .container {
    font-size: 0.7em;
  }
}
@media (max-width: 900px) {
  .links div,
  .links .home {
    font-size: 1.2em;
  }
  .container {
    height: 10%;
  }
}
