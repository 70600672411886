/* Container principal do carrinho */
.cartContainer {
  width: 400px;
  background-color: #ffffff73;
  border-radius: 15px;
  padding: 2rem;
  color: #333;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 20px;
  margin: 1.5rem;
  font-family: "Montserrat", sans-serif;
  overflow: hidden;
}

/* Cabeçalho do carrinho */
.cartHeader {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: bold;
  color: #00796b;
  margin-bottom: 1.5rem;
}

.cartIcon {
  margin-right: 0.75rem;
  color: #00796b;
}

/* Itens do carrinho */
.cartItems {
  padding-right: 0.5rem; /* Adiciona um espaço para o scroll vertical */

  max-height: 350px;
  overflow-y: auto;
  margin-bottom: 1.5rem;
}

.cartItems ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.cartItem {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  background-color: rgba(211, 236, 243, 0.548);
  color: #333;
  border-radius: 12px;
  padding: 0.75rem;
  box-sizing: border-box; /* Inclui o padding na largura total */
  transition: transform 0.3s;
}
.cartItem:hover {
  transform: scale(1.02);
}

.cartImage {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  margin-right: 0.75rem;
  object-fit: cover;
}

.itemInfo {
  display: flex;
  flex-direction: column;
}

.itemName {
  font-weight: bold;
  font-size: 1.1rem;
}

.itemQuantity {
  font-size: 0.9rem;
  color: #777;
}

/* Total do carrinho */
.cartTotal {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  color: #00796b;
  margin-bottom: 1.5rem;
}

/* Botão de checkout */
.checkoutButton {
  text-align: center;
}

.checkoutButton a {
  text-decoration: none;
  background-color: #00796b;
  color: #ffffff;
  padding: 0.75rem 2rem;
  border-radius: 30px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;
}

.checkoutButton a:hover {
  background-color: #004d40;
  transform: translateY(-2px);
}

.emptyCart {
  text-align: center;
  font-size: 1.1rem;
  color: #ccc;
}
