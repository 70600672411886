/* Container Principal */
.container {
  padding: 2em;
  max-width: 800px;
  margin: auto;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  gap: 2em;
  background-color: #ffffff;
  border-radius: 12px;
}

/* Header */
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  font-size: 1.5em;
  color: #333;
}

.cartIcon {
  font-size: 1.5em;
  color: #266364;
}

/* Tabela */
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 1em;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.table th {
  background-color: #266364;
  color: #fff;
}

.productImage {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin-right: 1em;
}

/* Quantidade */
.quantity {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.quantity button {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  background-color: #e0e0e0;
  color: #333;
  transition: background-color 0.3s;
}

.quantity button:disabled {
  background-color: #ccc;
}

.quantity button:hover:not(:disabled) {
  background-color: #ccc;
}

/* Botão de Remover */
.removeButton {
  background-color: #ff4d4d;
  color: #fff;
  padding: 0.5em 1em;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.removeButton:hover {
  background-color: #d32f2f;
}

/* Footer */
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1em;
  border-top: 1px solid #ddd;
  font-size: 1.2em;
  color: #266364;
  flex-wrap: wrap;
}

.footer span {
  margin-bottom: 1em;
}

.requestButton {
  display: flex;
  align-items: center;
  gap: 0.5em;
  background-color: #266364;
  color: #fff;
  padding: 0.75em 1.5em;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s, transform 0.2s;
}

.requestButton:hover {
  background-color: #004d40;
  transform: translateY(-3px);
}

.emptyCart {
  text-align: center;
  font-size: 1.2em;
  color: #888;
}

/* Responsividade */
@media (max-width: 600px) {
  .container {
    padding: 1em;
  }

  .header {
    font-size: 1.2em;
  }

  .table th,
  .table td {
    padding: 0.5em;
    font-size: 0.9em;
  }

  .productImage {
    width: 40px;
    height: 40px;
  }

  .quantity button {
    width: 25px;
    height: 25px;
    font-size: 0.9em;
  }

  .removeButton {
    padding: 0.4em 0.8em;
    font-size: 0.9em;
  }

  .footer {
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }

  .requestButton {
    width: 80%;
    padding: 0.5em 1em;
    font-size: 0.9em;
    justify-content: center;
  }
}
