.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  padding: 1em;
  background-color: #5fcdce;
}

.logoImage {
  height: 80px;
  width: auto;
  background-color: #5fcdce;
  border-radius: 8px;
}

.contact {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.contactItem {
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: white;
  cursor: pointer;
  transition: color 0.3s ease;
}

.contactItem:hover {
  color: #f1ffb1; /* Cor de destaque ao passar o mouse */
}

.icon {
  font-size: 1.2em;
}

.text {
  font-size: 1em;
}

@media (max-width: 600px) {
  .container {
    flex-direction: column;
    align-items: center;
    padding: 0.5em;
  }

  .logoImage {
    height: 60px;
  }

  .contactItem {
    font-size: 0.9em;
  }
}
