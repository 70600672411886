.container {
  overflow: auto;
  transition: 1s;
  margin: 0;
  padding: 1em;
  background-image: url("../imgs/background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}
.container .welcome {
  width: 100%;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  transition: 1s;
  text-align: right;
  padding-right: 2em;
  font-size: 1.7em;
}
.welcome span,
.welcome p {
  color: #fff;
}

.bluePet {
  margin: 0;
  transition: 1s;
  padding: 0;
  font-weight: bold;
  font-size: 2.2em;
}
.bluePet span {
  display: flex;
  align-items: center;
}

.links {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 900px) {
  .container {
    background-size: cover;
  }
  .welcome {
    padding: 0;
    transition: 1s;
    background-color: rgba(
      255,
      165,
      0,
      0.7
    ); /* Cor convertida para RGB com opacidade de 0.7 */
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    font-size: 0.4em;
  }
  .welcome p {
    font-size: 1em;
  }
}
@media (max-width: 700px) {
  .welcome p {
    transition: 1s;
    font-size: 0.35em;
  }
  .links {
    flex-direction: column;
  }
  .links a {
    font-size: 1em;
  }
}
