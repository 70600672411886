.adminContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filterMenu {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filterMenu button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #5fcdce;
  color: white;
  cursor: pointer;
}

.filterMenu .active {
  background-color: #4da7a9;
}

.productList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.addProductCard {
  width: 200px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  cursor: pointer;
  font-size: 3rem;
  color: #5fcdce;
  position: relative;
}

.addProductCard .addIcon {
  font-size: 4rem;
}

.productCard {
  width: 200px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  background-color: white;
  position: relative;
}

.productCard img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.cardIcons {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
}

.editIcon,
.deleteIcon {
  cursor: pointer;
  font-size: 1.2rem;
}

.editIcon {
  color: #4da7a9;
}

.deleteIcon {
  color: #e74c3c;
}

.addFormCard {
  width: 200px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.addFormCard input,
.addFormCard select,
.addFormCard textarea {
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.addFormCard button {
  padding: 5px;
  border: none;
  border-radius: 4px;
  background-color: #5fcdce;
  color: white;
  cursor: pointer;
}
.addFormCard {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: 10px;
}

.input,
.textarea,
.select,
.fileInput {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}

.textarea {
  resize: none;
  height: 60px;
}

.select,
.fileInput {
  cursor: pointer;
}

.input:focus,
.textarea:focus,
.select:focus,
.fileInput:focus {
  border-color: #86b7fe;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}

.addButton,
.cancelButton {
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.addButton {
  background-color: #28a745;
  color: #fff;
  margin-top: 10px;
}

.cancelButton {
  background-color: #dc3545;
  color: #fff;
  margin-top: 5px;
}

.addButton:hover {
  background-color: #218838;
}

.cancelButton:hover {
  background-color: #c82333;
}
.loginContainer {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: auto;
  text-align: center;
}

.input {
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.loginButton {
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  cursor: pointer;
}

.loginButton:hover {
  background-color: #0056b3;
}
