.container {
  font-family: "Montserrat", sans-serif;
  position: relative;
  z-index: 0;
  cursor: pointer;
  box-sizing: border-box;
  width: 15em;
  background-color: rgba(211, 236, 243, 0.548);
  height: 25em;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: 1s;
}
.container img {
  border-radius: 30px;
}

.container:hover {
  transition: 1s;
  transform: scale(1.05);
  box-shadow: 0px 0px 5px 0px;
  background-color: #5fcdce80;
}
.container:hover .imagem,
.container:hover p,
.container:hover h3 {
  transition: 1s;
  display: none;
}
.conteudo {
  height: 6em;
  width: 100%;
  transition: 1s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h3 {
  padding-top: 1em;
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
}
.informacoes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2em;
}
.informacoes p {
  font-family: "Montserrat", sans-serif;
  margin: 0;
}
.imagem {
  width: 12em;
  height: 12em;
  object-fit: cover;
  border-radius: 15px;
  margin-bottom: 2em;
  box-sizing: border-box;
}
.button div {
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: 1s;
  border-radius: 30px; /* Bordas arredondadas */
  font-family: "Montserrat", sans-serif;
  font-size: 16px; /* Tamanho da fonte */
  font-weight: 400; /* Estilo da fonte (negrito) */
  color: #000; /* Cor do texto */
  cursor: pointer; /* Ícone de cursor ao passar por cima */
  border: none; /* Remova a borda padrão */
}

.button {
  border-radius: 30px;
  align-items: center;
  background-color: #e2f97d7a;
  height: 18em;
  width: 12em;
  position: absolute;
  top: 10%;
  display: none;
  transition: 3s;
  padding: 1em;
}
.button span {
  font-weight: 900;
}
.container:hover .button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 1s;
}

.container:hover .button div {
  transition: 1s;
}

.button svg {
  padding: 0.3em;
  font-size: 2.5em;
  margin: 0px;
}

@media (max-width: 1200px) {
  .container {
    width: 100%;
    padding: 0;
  }
}
@media (max-width: 900px) {
  .container:hover .button {
    display: none;
  }
  .container:hover {
    background-color: transparent;
  }
  .container:hover .imagem,
  .container:hover p,
  .container:hover h3 {
    transition: 1s;
    display: block;
  }
}
