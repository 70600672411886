.container {
  background-image: url("../imgs/pata3.jpg");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4em;
  margin: 0 auto;
  max-width: 1200px;
  border-radius: 15px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.heroSection {
  text-align: center;
  background: linear-gradient(45deg, #007bff, #00b8d4);
  padding: 3em;
  border-radius: 10px;
  color: #ffffff;
  margin-bottom: 3em;
}

.heroTitle {
  font-size: 3.5rem;
  font-weight: bold;
}

.heroSubtitle {
  font-size: 1.5rem;
  margin-top: 1em;
  opacity: 0.8;
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  gap: 2em;
  align-items: flex-start;
}

.imageContainer {
  flex: 1;
  overflow: hidden;
  border-radius: 15px;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.content {
  flex: 2;
  text-align: left;
}

.title {
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 1em;
}

.description {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.8;
  margin-bottom: 1.5em;
}

.highlight {
  color: #007bff;
  font-weight: bold;
}

.footerSection {
  text-align: center;
  margin-top: 3em;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  margin-top: 2em;
}

.icon {
  font-size: 2.5rem;
  color: #007bff;
  transition: transform 0.3s ease, color 0.3s ease;
}

.icon:hover {
  color: #0056b3;
  transform: scale(1.2);
}

@media (max-width: 900px) {
  .contentWrapper {
    flex-direction: column;
    gap: 1em;
  }

  .container {
    padding: 2em;
  }

  .heroTitle {
    font-size: 2.5rem;
  }

  .description {
    font-size: 1.1rem;
  }

  .icon {
    font-size: 2rem;
  }
}
