.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Cor escura de fundo com opacidade */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
}
.close {
  border-radius: 0;
  font-size: 1em;
  font-weight: bold;
  color: white;
  margin: 0;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}
.modalBox {
  max-height: 95%;
  max-width: 70%;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.modalContent .nome {
  border: none;
}
.bottomItems {
  background-color: red;
  display: flex;
  width: 100vh;
}
@media (max-width: 900px) {
  .modal {
    background-color: white;
    padding: 0;
  }
  .modalBox {
  }
  .modalBox input,
  .modalBox label {
    font-size: 0.7em;
  }
  .modalContent {
    max-width: 80vh;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .close {
    color: black;
  }

  .modalBox {
    font-size: 0.4em;
    max-height: 150%;
    margin: 0;
    padding: 1em;
    width: 80vh;
  }

  .modalBox p {
    margin-top: 2em;
  }
  .modalBox button {
    font-size: 0.5em;
    padding: 1em;
  }
}
