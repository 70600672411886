/* components/layout/LoadingSpinner.module.css */

.spinner {
  border: 8px solid #f3f3f3; /* Cor de fundo do círculo */
  border-top: 8px solid #3498db; /* Cor da barra de progresso */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite; /* Animação de rotação */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
