.container {
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1em;
  color: white;
  background-color: #5fcdce;
}
@media (max-width: 900px) {
  .container {
    font-size: 0.55em;
  }
}
